import React from 'react';
import propTypes from 'prop-types';
import {theme} from '../../../themes/default-theme';
import clsx from 'clsx';
import {Checkbox as MuiCheckbox, FormControlLabel}  from '@material-ui/core';
import {makeStyles}  from '@material-ui/core/styles';
import checkIcon from '../../../assets/icons/check.svg';
import RadioCheck from '../../../assets/icons/radio-button.svg';
import RadioCheckMobile from '../../../assets/icons/radio-button-mobile.svg';
import ReactHtmlParser from 'react-html-parser';


const useStyles = makeStyles({
  root: {
    padding: 10,
    [theme.breakpoints.up('md')]: {
      marginRight: 5,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 'unset',
    width: 20,
    height: 20,
    border: `1px solid ${theme.palette.middleGrey}`,
    backgroundColor: 'white',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      width: 25,
      height: 25,
      fontSize: 16,
    },
    '&.error': {
      border: `1px solid ${theme.palette.error.form}`,
    },
    'input:disabled ~ &': {
      backgroundColor: theme.palette.lightGrey,
    },
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage: `url(${RadioCheckMobile})`,
      [theme.breakpoints.up('md')]: {
        backgroundImage: `url(${RadioCheck})`
      },
      content: '""',
      transform: 'scale(0,0)',
      transition: 'transform 100ms ease-in-out',
      backgroundSize: '14px 14px',
      margin: '2px',

      [theme.breakpoints.up('md')]: {
        margin: '5px',
      }
    },
  },
  checkedIcon: {
    backgroundColor: `${theme.palette.vividBlue}`,
    '&:before': {
      transform: 'scale(1,1)'
    }
  }
});

const Checkbox = (props) => {
  const classes = useStyles();
  const {
    label,
    name,
    error
  } = props

  const muiProps = {
    ...props,
    error: undefined
  }

  return (
    <FormControlLabel
      control={
        <MuiCheckbox
          className={classes.root}
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={clsx(classes.icon, error && 'error')} />}
          inputProps={{ 'aria-label': `${name} checkbox` }}
          {...muiProps}
        />
      }
      label={ReactHtmlParser(label)}
    />
  )
}
  
Checkbox.propTypes = {
  label: propTypes.string,
  onChange: propTypes.func,
  name: propTypes.string.isRequired,
  error: propTypes.bool
}
  
Checkbox.defaultProps = {
  label: '',
  onChange: () => {},
  error: false
}
  
export default Checkbox